import React from 'react'
import Script from 'next/script'
import { LISTRAK_ID } from '@/services/Configuration'
import { useContentLoaded } from '@/hooks/useContentLoaded'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'

const Listrak: React.FC = () => {
  const contentLoaded = useContentLoaded()
  if (!contentLoaded || !isFeatureEnabled('listrakAPIEnabled')) {
    return null
  }
  return (
    <Script
      strategy="lazyOnload"
      data-testid="test-listrakId"
      src={`https://cdn.listrakbi.com/scripts/script.js?m=${LISTRAK_ID}&v=1`}
    />
  )
}

export default Listrak
